// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true
  };

  export const endpoint = {
    endpointurl:"http://127.0.0.1:8080/Seguros/",
    endpointurlensured:"http://127.0.0.1:8080/insurances/parameterization/api/",
    endpointParametrizationProd:"https://captaciones-int-dev.apps.ambientesbc.com/seguros/sisparameterization/api/",
    endpointProductsProd:"https://captaciones-int-dev.apps.ambientesbc.com/seguros/sisventas/",
    endpointPolicyProd:"https://captaciones-int-dev.apps.ambientesbc.com/seguros/sispoliza/api/",
    apiurl:"https://captaciones-int-dev.apps.ambientesbc.com/seguros/sisventas/",
    endpointLists:"https://captaciones-int-dev.apps.ambientesbc.com/seguros/sisventas/list/",
    urlCardif:"https://webview-uat.cardif.com.co/wv_bancolombia/",
    urlSeguros:"https://captaciones-int-dev.apps.ambientesbc.com/seguros/"
  }

  export const msal={
    clientId:"e627b993-7f96-47fa-b866-94833fcebbc6",
    redirectUri:"https://seguros-dev-sis.apps.ambientesbc.com",
    tenant: '428f4e2e-13bf-4884-b364-02ef9af41a1d'
  }
